<template>
  <div @keyup.enter="validateForm()">
    <div class="container">

      <div class="row align-items-center">
        <div class="col-md-3"></div>

        <div class="col-md-6 login-container">
          <div class="card login">
            <div class="card-body">
              <h4 class="card-title pb-4">{{ $t('views.forms.login_title') }}</h4>
              <div v-if="resetingPassword" class="reset-mssg">{{ resetMssg }}</div>
              <div v-if="wrongCredentials" class="invalid-mssg">{{ credentialsMssg }}</div>
              <p class="card-text pb-4" v-html="$t('views.forms.login_body', { firma: firmaName } )"></p>
              <input
                type="email"
                class="form-control mb-4 text-field"
                :class="{ 'first-input': !submitted || !errors.has('Email')} "
                :placeholder="$t('views.forms.place_holders.email')"
                v-model="username"
                name="Email"
                v-validate="'required|email'"
              />
              <div v-if="submitted" class="red-text m-2 error-mssg">{{ errors.first('Email') }}</div>
              <div class="password-input">
                <input
                  type="password"
                  id="input-pwd"
                  class="form-control mb-4 text-field"
                  :placeholder="$t('views.forms.place_holders.password')"
                  name="Password"
                  v-model="password"
                  v-validate="'required'"
                />
                <span
                  class="show-eye"
                  toggle="#password-field"
                  :class="eyeClass"
                  @click="togglePassword()"
                ></span>
              </div>
              <div v-if="submitted" class="red-text m-2 error-mssg">{{ errors.first('Password') }}</div>
              
              <div class="form-check">
                <input 
                  type="checkbox" 
                  class="form-check-input" 
                  id="anonymous" 
                  v-model="anonymous" />
                <label
                  class="form-check-label white-text checkbox-text"
                  for="anonymous"
                >
                  <span class='login__anonymous'>Anonym anmelden</span>
                  <br />
                  <span class='login__anonymous--full'>{{ $t('views.anonymous') }}</span>
                </label>
              </div>
              <button
                class="btn btn-block mt-2"
                @click="validateForm()"
              >{{ $t('views.forms.login') }}</button>
              <div class="card-text footer pt-4 pd-4">
                <p class="register-text">
                  {{ $t('views.forms.new_user') }}
                  <a class="white-text" @click="goToRegister()">
                    <span class="link__hover">{{ $t('views.forms.here') }}</span>
                  </a>
                </p>
                <p class="reset-text">
                  Passwort vergessen? Fordern Sie 
                  <a
                    class="reset-password"
                    @click="resetPassword"
                  ><span class="link__hover">HIER</span>
                  </a>
                  ein neues an.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      submitted: false,
      resetingPassword: this.$route.query.resetPassword || false,
      type: 'password',
      eyeClass: 'fa fa-fw fa-eye field-icon toggle-password',
      firmaName: '',
      inviterId: '',
      resetMssg: this.$t('views.forms.responses.reset_done'),
      wrongCredentials: false,
      credentialsMssg: this.$t('views.forms.responses.wrong_credentials'),
      anonymous: false
    };
  },
  methods: {
    checkBrowser(){
      if(this.$browserDetect.isOpera){
        if (
          window.confirm(
            'Achtung! Sie nutzen den Opera-Webbrowser zum Abrufen dieser virtuellen Veranstaltung. Bitte nutzen Sie einen moderneren Browser wie Google Chrome oder Mozilla Firefox für fehlerfreie Funktion. Wir empfehlen Ihnen, Ihre Software zu modernisieren und z.B. Google Chrome zu installieren. Drücken Sie "OK" um zur Webseite von Google Chrome zu gelangen.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      } else if(this.$browserDetect.isIE){
        if (
          window.confirm(
            'Achtung! Sie nutzen den veralteten Internet Explorer-Webbrowser zum Abrufen dieser virtuellen Veranstaltung. Bitte nutzen Sie einen moderneren Browser wie Google Chrome oder Mozilla Firefox für fehlerfreie Funktion. Wir empfehlen Ihnen, Ihre Software zu modernisieren und z.B. Google Chrome zu installieren. Drücken Sie "OK" um zur Webseite von Google Chrome zu gelangen.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      } else if(this.$browserDetect.isEdge){
        if (
          window.confirm(
            'Achtung! Sie nutzen den Microsoft Edge-Webbrowser in einer älteren Version zum Abrufen dieser virtuellen Veranstaltung. Teile der Funktionen könnten eingeschränkt sein. Bitte nutzen Sie einen moderneren Browser wie Google Chrome oder Mozilla Firefox für fehlerfreie Funktion. Wir empfehlen Ihnen, Ihre Software zu modernisieren und z.B. Google Chrome zu installieren. Drücken Sie "OK" um zur Webseite von Google Chrome zu gelangen.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      } else if(this.$browserDetect.isIOS){
        if (
          window.confirm(
            'Achtung! Sie nutzen den Safari für iOS Browsern zum Abrufen dieser virtuellen Veranstaltung. Bitte nutzen Sie einen moderneren Browser wie Google Chrome oder Mozilla Firefox für fehlerfreie Funktion. Wir empfehlen Ihnen, Ihre Software zu modernisieren und z.B. Google Chrome zu installieren. Drücken Sie "OK" um zur Webseite von Google Chrome zu gelangen.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      } else if(this.$browserDetect.isSafari){
        if (
          window.confirm(
            'Sie nutzen Apple Safari zum Abruf der Webseite. Safari unterstützt keine modernen Web-Anwendungen. Bitte drücken Sie auf „OK“, um auf die Download-Seite von Google Chrome zu gelangen, und installieren Sie sich den Google Chrome Webbrowser zum Abruf der Plattform.'
          )
        ) {
          window.location.href =
                  'https://www.google.com/chrome/browser/index.html';
        }
      }
    },
    togglePassword() {
      if (this.eyeClass.includes('fa-eye-slash')) {
        this.eyeClass = this.eyeClass.replace('fa-eye-slash', 'fa-eye');
        document.getElementById('input-pwd').type = 'password';
      } else {
        this.eyeClass = this.eyeClass.replace('fa-eye', 'fa-eye-slash');
        document.getElementById('input-pwd').type = 'text';
      }
    },
    goToRegister() {
      this.$router.push(
        '/register?inviter=' + this.firmaName + '&inviter_id=' + this.inviterId
      );
    },
    login() {
      this.$store
        .dispatch('authUser', {
          username: this.username,
          password: this.password,
          anonymous: this.anonymous
        })
        .then(() => {
          this.$router.push('/intro-video');
        })
        .catch(() => {
          this.wrongCredentials = true;
        });
    },
    validateForm() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.login();
        }
      });
    },
    resetPassword() {
      this.$router.push('/reset-password');
    }
  },
  created() {
    this.firmaName = this.$route.query.inviter || 'Häfele';
    this.inviterId = this.$route.query.inviter_id || '4';
  },
  mounted() {
    this.checkBrowser();
  }
};
</script>

<style lang="scss" scoped>
.header {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: -1;
}


.error-mssg {
  font-size: 12px;
  margin-top: 0px !important;
  margin-bottom: 4% !important;
  text-shadow: 0px 0px 10px #000;
}

.error-holder {
  margin-top: 4px;
}

.toggle-password:hover {
  cursor: pointer;
}

.login{

  &-container {
    margin: auto;
  }

  &__anonymous{
    font-weight: 300 !important;
    font-size: 16px !important;
    line-height: 1.6em;
    
    &--full{
      font-size: 10px !important;
      font-weight: 300;
    }
  }
}

.link__hover{
  &:hover{
    color: $secondaryColor !important;
  }
}

.container {
  margin-top: $loginMarginTop;
}

input {
  font-size: 16px;
  letter-spacing: 0.15px !important;
  font-weight: 400;
}

.login {
  background-color: rgba(80, 80, 80, 0.9);
  margin-bottom: 50px !important;
  padding-bottom: 20px;
}

.footer {
  padding-top: 5% !important;
  padding-bottom: 5% !important;
  height: 60px;
}

.card {
  width: 100%;
  border-radius: 0;
  box-shadow: 1px 1px 5px rgba(80, 80, 80, 1);
}

.register-text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.49px;
}

.register-text span {
  font-size: 16px;
  font-weight: 700;
}

.reset-text{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.49px;
  padding-bottom: 10px;

    span{
      font-size: 16px;
      font-weight: 700;
    }
}

.login .card-title {
  padding-bottom: 4% !important;
  margin-top: 4%;
  font-size: 42px;
  font-weight: 300;
  color: $secondaryColor;
  letter-spacing: 1.2px !important;
  opacity: 1;
}

.login .text-field {
  border-radius: 0 !important;
  background-color: rgba(80, 80, 80, 0.9);
  color: white;
  border-color: rgba(255, 255, 255, 0.7);
}

.login ::placeholder {
  color: white;
  opacity: 0.5;
}

.card .card-body .card-text {
  letter-spacing: 0.49px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.49px !important;
  opacity: 1;
  font-weight: 400;
  padding-bottom: 6% !important;
}

.card-body {
  width: 94%;
  margin: 0% auto;
  padding: 2rem;

  @include respond(laptop){
    overflow-y: scroll;
    height: 70vh;
  }
}

.field-icon {
  float: right;
  margin-right: 10px;
  margin-top: -53px;
  position: relative;
  z-index: 2;
  color: white;
  opacity: 0.8;
}

.reset-mssg {
  color: green;
  font-size: 12px;
  margin-top: 4% !important;
  margin-bottom: 4% !important;
}

.invalid-mssg {
  color: red;
  font-size: 12px;
  margin-top: 4% !important;
  margin-bottom: 4% !important;
}

.checkbox-text {
  font-size: 12px;
}

.login .btn {
  color: black;
  background-color: white;
  height: 37px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.15px;
  padding-left: 7%;
  padding-top: 1.5%;
  text-transform: capitalize;
  margin-top: 13% !important;
  border-radius: 0 !important;
}

.login .btn:hover {
  background-color: $secondaryColor;
}

.reset-password {
  color: white !important;
  font-size: 16px !important;
  font-weight: 700 !important;

  &:hover {
    color: $secondaryColor !important;
  }
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  .card {
    margin-top: 0px !important;
    margin-bottom: 4% !important;
  }

  .error-holder {
    margin-top: 4px;
  }

  .toggle-password:hover {
    cursor: pointer;
  }

  .login-container {
    margin: auto;
  }

  .container {
    margin-top: 7px;
  }

  input {
    font-size: 16px;
    letter-spacing: 0.15px !important;
    font-weight: 400;
  }

  .first-input {
    margin-bottom: 20px !important;
  }
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .card {
    margin-top: 0px;
  }

  .login ::placeholder {
    color: #ffffff99;
    opacity: 0.5;
  }

  .card .card-body .card-text {
    letter-spacing: 0.49px;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.49px !important;
    opacity: 1;
    font-weight: 400;
    padding-bottom: 6% !important;
  }

  .card-body {
    width: 490px !important;
    margin: 0% auto;
  }

  .field-icon {
    margin-top: -30px;
  }

  input {
    font-size: 8px;

    .reset-mssg {
      color: green;
      font-size: 12px;
      margin-top: 4% !important;
      margin-bottom: 4% !important;
    }

    .invalid-mssg {
      color: red;
      font-size: 12px;
      margin-top: 4% !important;
      margin-bottom: 4% !important;
    }

    .checkbox-text {
      font-size: 12px;
    }

    .login .btn {
      color: black;
      background-color: white;
      height: 37px;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.15px;
      padding-left: 7%;
      padding-top: 1.5%;
      text-transform: capitalize;
    }

    p {
      font-size: 10px !important;
    }

    button {
      font-size: 10px !important;
      padding-left: 15px !important;
      padding-top: 10px !important;
    }

    header {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;

      .form-check {
        margin-top: 13% !important;
      }
    }


  }
  /* #### Mobile Phones Landscape #### */
  @media screen and (max-device-width: 640px) and (orientation: landscape) {
    .card {
      margin-top: 0px !important;
    }
    header {
      visibility: hidden;
      clear: both;
      float: left;
      margin: 10px auto 5px 20px;
      width: 28%;
      display: none;
    }
  }
}

@media screen and (max-device-width: 1024px) {
  .card {
    margin-top: 20% !important;
  }
  header {
    visibility: hidden;
    clear: both;
    float: left;
    margin: 10px auto 5px 20px;
    width: 28%;
    display: none;
  }
}
</style>
